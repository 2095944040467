<script>
import Layout from "../../layouts/main";
import VideosLayout from "../../layouts/videosLayout.vue";
import errorHandler from "@/helpers/errorHandler";

/**
 * Starter component
 */
export default {
  page: {
    title: "Videos Credentials",
  },
  head() {
    return {
      title: `Videos-Credentials`,
    };
  },
  components: {
    Layout,
    VideosLayout
  },
  async created() {
    await this.$store.dispatch("video/getCredentials").then(()=>{}).catch( err =>{
      // errorHandler.methods.errorMessage(err);
      console.log("No items")
    })
  },
  computed: {
    credentials(){
        return this.$store.getters['video/allCredentials']
    }
  },
  methods: {

  },
  data: () => ({

  })
};
</script>

<template>
  <Layout>
    <VideosLayout>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h2 class="card-title">
                Credentials: 
            </h2>
            <h3 class="text-primary" v-if="credentials"> API key : <strong class="text-dark">{{ credentials.api_key }}</strong></h3>
            <h3 class="text-primary" v-if="credentials"> Temp API key : <strong class="text-dark">{{ credentials.temp_api_key }}</strong></h3>
            <div class="row mt-2">
                <div class="col-12  text-center" v-if="!credentials">
                    <b-button variant="success" pill type="submit" style="width: 15rem;" :to="`/videos/credentials/create`">
                    Create
                    </b-button>
                </div>
                <div class="col-12  text-center" v-else>
                    <b-button variant="warning" pill type="submit" style="width: 15rem;" :to="`/videos/credentials/key/update`">
                    Edit
                    </b-button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </VideosLayout>
  </Layout>
</template>
